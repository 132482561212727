import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
// components
import Btn from '@/components/Button/Btn.vue';
import ProjectCard from '@/views/Projects/Components/ProjectCard/ProjectCard.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import SelectInput from '@/components/FormInputs/SelectInput/SelectInput.vue';
import CoverImage from '@/components/Elements/CoverImage.vue';
let ProjectsTopicSearch = class ProjectsTopicSearch extends Vue {
    constructor() {
        super(...arguments);
        this.filteredTopic = null;
        this.loading = true;
        this.coverImageDesc = "";
    }
    async onTopicFilterChange(newVal) {
        this.loading = true;
        // Set the filter ID and reset page
        await this.$store.dispatch('projects/setFilter', newVal);
        if (this.$route.query.label !== newVal) {
            await this.$store.dispatch('projects/setPage', 1);
        }
        else {
            await this.$store.dispatch('projects/setPage', this.$route.query.page);
        }
        // Call the page load
        await this.$store.dispatch('projects/getProjects');
        this.loading = false;
    }
    created() {
        if (history.state.label && history.state.page) {
            if (this.$route.fullPath === `/projects?label=${history.state.label}&page=${history.state.page}`) {
                return;
            }
            else {
                this.$router.push({ path: `/projects?label=${history.state.label}&page=${history.state.page}` });
            }
        }
    }
    async mounted() {
        await this.$store.commit('stories/SET_EMPTY');
        await this.$store.commit('lodges/SET_EMPTY');
        await this.$store.commit('discussions/SET_EMPTY');
        await this.resetProjectValues();
        if (this.$route.query.page) {
            await this.$store.dispatch('projects/setPage', this.$route.query.page);
        }
        if (this.$route.query.label) {
            await this.$store.dispatch('projects/setFilter', this.$route.query.label);
            this.filteredTopic = this.$store.getters['projects/getFilter'];
        }
        await this.$store.commit('projects/SET_ONLY_FAVOURITES', false);
        // Call the page load
        await this.$store.dispatch('projects/getProjects');
        this.loading = false;
        await this.$store.dispatch('label/getAllLabels');
    }
    get sortedLabels() {
        let sortedLabels = this.labels;
        sortedLabels.sort((a, b) => {
            let lowerCaseA = a.name.toLowerCase();
            let lowerCaseB = b.name.toLowerCase();
            if (lowerCaseA < lowerCaseB) {
                return -1;
            }
            if (lowerCaseA > lowerCaseB) {
                return 1;
            }
            return 0;
        });
        return sortedLabels;
    }
    get filteredTopicName() {
        let labelName = null;
        this.labels.forEach((label) => {
            if (label.id === this.filteredTopic) {
                labelName = label.name;
            }
        });
        return labelName;
    }
    async beforeDestroy() {
        await this.resetProjectValues();
    }
    async resetProjectValues() {
        await this.$store.dispatch('projects/setFilter', '');
        await this.$store.dispatch('projects/setPage', 1);
    }
    async goToProjectsPage() {
        if (this.filteredTopic != null) {
            this.$router.push({ path: `/projects?label=${this.filteredTopic}` });
        }
        else {
            this.$router.push({ path: `/projects` });
        }
    }
    async goToForum() {
        if (this.filteredTopic != null) {
            this.$router.push({ path: `/discussions/topic-search?label=${this.filteredTopic}` });
        }
        else {
            this.$router.push({ path: `/discussions/topic-search` });
        }
    }
    async goToStories() {
        if (this.filteredTopic != null) {
            this.$router.push({ path: `/stories/topic-search?label=${this.filteredTopic}` });
        }
        else {
            this.$router.push({ path: `/stories/topic-search` });
        }
    }
    handleClickEvent(e) {
        this.filteredTopic = e.id;
    }
};
__decorate([
    Getter('getProjects', { namespace: 'projects' })
], ProjectsTopicSearch.prototype, "projects", void 0);
__decorate([
    Getter('getLabels', { namespace: 'label' })
], ProjectsTopicSearch.prototype, "labels", void 0);
__decorate([
    Watch('filteredTopic')
], ProjectsTopicSearch.prototype, "onTopicFilterChange", null);
ProjectsTopicSearch = __decorate([
    Component({
        components: {
            Btn,
            ProjectCard,
            TextInput,
            SelectInput,
            CoverImage,
        },
        metaInfo() {
            return {
                title: 'Africa Geographic | project topic search results',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic projects topic search results',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/projects/topic-search` }],
            };
        },
    })
], ProjectsTopicSearch);
export default ProjectsTopicSearch;
